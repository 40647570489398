<template>
  <div class="user-result">
	<div id="canvas" v-if="resultInfo!==''">
		<div class="user-title">您的个人信息</div>
		<div class="user-info">
			<div class="content">
				<h1 id="username">姓名: {{form.username}}</h1>
				<h1 id="gender">性别: {{Number(form.gender) === 0 ? '女': '男'}}</h1>
			</div>
			<div style="width:100%;">
				<h1 id="">生辰: {{form.date}} {{form.hour}}时</h1>
				<p>{{ resultInfo.user.lDate }}</p>
			</div>
		</div>
		<div class="content">
			<div class="title">性格相关</div>
			<div class="item">
				<div>
					<h1 class="tit">性格分析:</h1>
					<p v-html="resultInfo.rglm.xgfx"></p>
				</div>
				<div>
					<h1  class="tit">优点:</h1>
					<p v-html="resultInfo.data.zonghe.yx"></p>
				</div>
				<div>
					<h1  class="tit">缺点:</h1>
					<p v-html="resultInfo.data.zonghe.qd"></p>
				</div>
				<div>
					<h1  class="tit">注意事项:</h1>
					<p v-html="resultInfo.data.zonghe.zhuyi"></p>
				</div>
			</div>
			<div class="title">事业相关</div>
			<div class="item">
				<div>
				<h1  class="tit">喜用神事业发展方向:</h1>
				<p>{{resultInfo.data.xiyongshen.shiye}}</p>
				</div>
				<div>
				<h1  class="tit">事业分析:</h1>
				<p>{{resultInfo.rglm.syfx}}</p>
				</div>
			</div>
			<div class="title">财运相关</div>
			<div class="item">
				<div>
					<h1 class="tit">流年财运:</h1>
					<p v-html="resultInfo.data.zonghe.lncy"></p>
				</div>
				<div>
					<h1  class="tit">先天财运:</h1>
					<p v-html="resultInfo.data.zonghe.xtcy"></p>
				</div>
				<div>
					<h1  class="tit">财运分析:</h1>
					<p v-html="resultInfo.rglm.cyfx"></p>
				</div>
			</div>
			<div class="title">健康方面</div>
			<div class="item">
				<div>
					<h1  class="tit">健康分析:</h1>
					<p v-html="resultInfo.rglm.jkfx"></p>
				</div>
				<div>
					<h1  class="tit">五行健康旺和衰:</h1>
					<p>旺：{{resultInfo.info.wharr.wang}}</p>
					<p>缺：{{resultInfo.info.wharr.que}}</p>
					<p>易患疾病：{{resultInfo.info.wharr.whjk.jb}}</p>
					<p>预防建议：{{resultInfo.info.wharr.whjk.yd}}</p>
				</div>
			</div>
			<div class="title">三命通会</div>
			<div class="item">
				<div>
					<h1  class="tit">三命通会:</h1>
					<p v-html="resultInfo.sxth.tf1"></p>
					<p v-html="resultInfo.sxth.tf2"></p>
				</div>
			</div>
		</div>
	</div>
	<div class="loading-items" id="loading-items" v-if="resultInfo === ''">
		<div class="loading">
			<div class="loading_mask"></div>
			<div class="weui-loadmore">
			<div class="weui-loading"></div>
			<div class="weui-loadmore__tips">测算结果正在加载中 大约5-7秒 请不要退出~</div>
			</div>
		</div>
	</div>
	<div class="loading-items" id="loading-items" v-if="isConver">
      <div class="loading">
        <div class="loading_mask"></div>
        <div class="weui-loadmore">
        	<div class="weui-loading"></div>
        	<div class="weui-loadmore__tips">结果图片生成中，请不要退出~</div>
      	</div>
	  </div>
	</div>
	<div class="button-items" v-if="resultInfo !== ''">
      <div class="notice">
        为保护您的隐私 系统不记录测算结果
      </div>
      <input type="button" :disabled="isConver ? true : false" value="点击生成图片" @click="saveImg" />
    </div>
  </div>
</template>
<style src="./style.css"></style>
<script>
import html2canvas from 'html2canvas'
//import wx from 'weixin-js-sdk'
import { getBaziData } from '../api/index'
  export default{
	data() {
	  return {
		resultInfo: '',
		imagePhoto: '',
		height: 0,
		form: {},
		isConver: false
	  }
	},
	created() {
	  const params = {
		username: this.$route.query.username,
		gender:this.$route.query.gender,
		date: this.$route.query.date,
		hour: Number(this.$route.query.hour)
	  }
	  this.form = params
	},
	mounted() {
	  if(this.form.username) {
		//window.location.reload();
		this.initData(this.form)
	 }

	},
	methods: {
	  async initData(params) {
		const res = await getBaziData(params)
		if(res.data) {
			//console.log(res.data)
			this.resultInfo =  res.data
			// setTimeout(() => {
			// 	this.taskScreenshort()
			// },2000)
		}
	  },
	  taskScreenshort(dom) {
		//const dom = document.getElementById("canvas")
		const width = dom.offsetWidth
		this.height = dom.offsetHeight
		let scale = 2 // 设定倍数这样不会模糊
		return new Promise((resolve,reject) => {
			html2canvas(dom, {
				allowTaint: true, //允许被污染
				width: width,
				heigth: this.height,
				windowHeight: this.height,
				windowWidth: width,
				scale: scale,
				scrollX: 0,
				scrollY: 0,
				y: 0,
				x: -10,
				useCORS: true
			}).then(canvas => {
				resolve(canvas)
			}).catch(err => {
				reject(err)
			})
		})
       },
	   saveImg() {
		//alert(document.getElementById("canvas"))
		this.isConver = true
		this.taskScreenshort(document.getElementById("canvas")).then(canvas => {
			this.imagePhoto = canvas.toDataURL('image/png', 1.0) //将图片转为base64
			//alert(this.imagePhoto)
			localStorage.setItem('base64', this.imagePhoto)
			//console.log(this.imagePhoto)
			this.isConver = false
			window.location.href= `#/showImg?height=${this.height}`;
			
		})
	   }
	}
  }
</script>